//Core
import React, { useEffect, forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
//Actions
import { fileActions, journalActions } from '../../actions';
import { clearJournalFiles, clearQrCodeSignURL, clearQrCodeStampURL, clearStateStamped, clearRemovedFile, setJournalFiles } from '../../reducers';
//Components
import { Loader, Button, Pagination, QrSignPopUp } from '../../components';
//Helpers
import { getFullDate, isRoleForSign, isRoleForRemove } from '../../helpers';
//Hooks
import { useSign } from '../../hooks';
//Styles
import Styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const states = {
    all: [],
    signed: ['SIGNED'],
    notSigned: ['NOT_SIGNED', 'ERROR', 'FORBID_SIGN'],
    accountantSigned: ['ACCOUNTANT_SIGNED'],
    ownerSigned: ['OWNER_SIGNED'],
    deleted: ['DELETED'],
};

const JournalFiles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const journalFilesData = useSelector((state) => state.journalData.journalFiles);
    const removedFile = useSelector((state) => state.journalData.removedFile);
    const roles = useSelector((state) => state.authData.data?.roles);
    const stampURL = useSelector((state) => state.file.stampURL);
    const signURL = useSelector((state) => state.file.signURL);
    const stateStamped = useSelector((state) => state.file.stateStamped);
    const loading = useSelector((state) => state.ui.loader);

    const [currentFilter, setCurrentFilter] = useState('all');
    const [keyForPagination, setKeyForPagination] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [signFileName, setFileName] = useState(null);

    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [shouldErrorRender, setShouldRender] = useState(false);

    useEffect(() => {
        dispatch(journalActions.getJournalFiles(navigate));
        return () => {
            dispatch(clearJournalFiles());
        };
    }, []);

    useEffect(() => {
        const params = {};
        if (dateRange[0] && dateRange[1]) {
            params.from = getFullDate(dateRange[0]);
            params.to = getFullDate(dateRange[1]);

            if (states[currentFilter].length) {
                params.state = states[currentFilter];
            }
            dispatch(journalActions.getJournalFiles(navigate, params));
            setKeyForPagination(keyForPagination + 1);
        }
    }, [dateRange]);

    useEffect(() => {
        if (removedFile) {
            const data = { ...journalFilesData };
            data.content = data.content.filter((item) => item.fileName !== removedFile);
            dispatch(setJournalFiles(data));
            dispatch(clearRemovedFile());
        }
    }, [removedFile]);

    useSign(signFileName);

    const CustomInput = forwardRef(({ value, onClick, ...rest }, ref) => (
        <button className={Styles.dateInput} onClick={onClick} ref={ref}>
            {value ? value : 'Операції за'}
        </button>
    ));

    const paginationClick = (nav, i) => {
        const params = {
            page: i,
        };
        if (states[currentFilter].length) {
            params.state = states[currentFilter];
        }
        if (dateRange[0] && dateRange[1]) {
            params.from = getFullDate(dateRange[0]);
            params.to = getFullDate(dateRange[1]);
        }
        dispatch(clearJournalFiles());
        dispatch(journalActions.getJournalFiles(nav, params));
    };

    const signFn = (fileName) => {
        if (!isRoleForSign(roles?.[0]) || signURL || stampURL) {
            return;
        }
        dispatch(fileActions.signRequestAction({ fileName }, navigate));
    };

    const removeFn = (fileName) => {
        if (!isRoleForRemove(roles?.[0])) {
            return;
        }
        // const params = {};
        // if (dateRange[0] && dateRange[1]) {
        //     params.from = getFullDate(dateRange[0]);
        //     params.to = getFullDate(dateRange[1]);
        // }
        // if (states[currentFilter].length) {
        //     params.state = states[currentFilter];
        // }
        // dispatch(journalActions.removeFileAction({fileName, params, setKeyForPagination, keyForPagination}, navigate));
        dispatch(journalActions.removeFileAction(fileName, navigate));
    };

    const showSignButton = (state, role) => {
        if (
            (state === 'NOT_SIGNED' && isRoleForSign(role)) ||
            (state === 'ERROR' && isRoleForSign(role)) ||
            (state === 'FORBID_SIGN' && isRoleForSign(role))
        ) {
            return true;
        }
        if (state === 'ACCOUNTANT_SIGNED' && role === 'STATE_OWNER') {
            return true;
        }
        if (state === 'OWNER_SIGNED' && role === 'STATE_ACCOUNTANT') {
            return true;
        }
    };

    const showRemoveButton = (state, role) => {
        if (
            (state === 'NOT_SIGNED' && isRoleForRemove(role)) ||
            (state === 'FORBID_SIGN' && isRoleForRemove(role)) ||
            (state === 'ACCOUNTANT_SIGNED' && isRoleForRemove(role)) ||
            (state === 'ERROR' && isRoleForRemove(role)) ||
            (state === 'OWNER_SIGNED' && isRoleForRemove(role))
        ) {
            return true;
        }
    };

    const filterMenuHandler = (tab) => {
        setCurrentFilter(tab);
        const params = {};
        if (dateRange[0] && dateRange[1]) {
            params.from = getFullDate(dateRange[0]);
            params.to = getFullDate(dateRange[1]);
        }
        dispatch(clearJournalFiles());
        if (tab !== 'all') {
            params.state = states[tab];
        }
        dispatch(journalActions.getJournalFiles(navigate, params));
    };

    const dateFormatFn = (date) => {
        if (!date || typeof date !== 'string') {
            return;
        }
        const arrayString = date.split('-').reverse();
        return arrayString.join('.');
    };

    const toggleIsErrorVisible = () => {
        if (isErrorVisible) {
            return;
        }
        setShouldRender(true);
        setIsErrorVisible(true);
        setTimeout(() => setIsErrorVisible(false), 5000);
        setTimeout(() => setShouldRender(false), 5500);
    };

    return (
        <>
            <div className={Styles.buttonContainer}>
                <div onClick={() => filterMenuHandler('all')} className={currentFilter === 'all' ? Styles.active_tab : Styles.tab}>
                    Усі
                </div>
                <div onClick={() => filterMenuHandler('signed')} className={currentFilter === 'signed' ? Styles.active_tab : Styles.tab}>
                    Підписані
                </div>
                <div onClick={() => filterMenuHandler('notSigned')} className={currentFilter === 'notSigned' ? Styles.active_tab : Styles.tab}>
                    Не підписані
                </div>
                {/*//Todo*/}
                {/*//move to help fn*/}
                {roles?.[0] === 'STATE_OWNER' || roles?.[0] === 'STATE_ACCOUNTANT' || roles?.[0] === 'STATE_ASSISTANT' ? (
                    <>
                        <div
                            onClick={() => filterMenuHandler('accountantSigned')}
                            className={currentFilter === 'accountantSigned' ? Styles.active_tab : Styles.tab}
                        >
                            Підписані бухгалтером
                        </div>
                        <div
                            onClick={() => filterMenuHandler('ownerSigned')}
                            className={currentFilter === 'ownerSigned' ? Styles.active_tab : Styles.tab}
                        >
                            Підписані директором
                        </div>
                    </>
                ) : null}
                <div onClick={() => filterMenuHandler('deleted')} className={currentFilter === 'deleted' ? Styles.active_tab : Styles.tab}>
                    Видалені
                </div>
            </div>
            <div className={Styles.picker_wrap}>
                <DatePicker
                    dateFormat={'yyyy-MM-dd'}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        if (!update[0] && !update[1]) {
                            const params = {
                                page: 0,
                            };
                            if (states[currentFilter].length) {
                                params.state = states[currentFilter];
                            }
                            dispatch(journalActions.getJournalFiles(navigate, params));
                            setKeyForPagination(keyForPagination + 1);
                        }
                    }}
                    isClearable={true}
                    customInput={<CustomInput />}
                />
            </div>
            <div className={Styles.doc_wrap}>
                <div className={Styles.title_item}>
                    <div className={Styles.doc_title}>Файли</div>
                    <div className={Styles.doc_title_container}>
                        <div className={Styles.doc_title}>Дата завантаження</div>
                        {currentFilter === 'deleted' ? <div className={Styles.doc_title}>Дата видалення</div> : null}
                    </div>
                </div>
                {stampURL && signFileName ? (
                    <QrSignPopUp
                        titleText={'Для накладання печатки відскануйте qr-код у мобільному додатку "Privat24-business"'}
                        key={stampURL}
                        fileName={signFileName}
                        QrUrl={stampURL}
                        closeAction={[clearQrCodeStampURL, clearQrCodeSignURL, clearStateStamped]}
                    />
                ) : loading && journalFilesData ? (
                    <div className={Styles.qrLoader}>
                        <Loader />
                    </div>
                ) : null}

                {signURL && signFileName && stateStamped ? (
                    <QrSignPopUp
                        titleText={'Для накладання підпису відскануйте qr-код у мобільному додатку "Privat24-business"'}
                        key={signURL}
                        fileName={signFileName}
                        QrUrl={signURL}
                        closeAction={[clearQrCodeStampURL, clearQrCodeSignURL, clearStateStamped]}
                    />
                ) : null}

                {journalFilesData && journalFilesData?.content ? (
                    journalFilesData.content.map(({ fileName, createAt, state, modifiedAt }) => {
                        return (
                            <div className={Styles.doc_item}>
                                <NavLink className={Styles.doc} key={fileName} to={`/journal/${fileName}/${currentFilter}`}>
                                    {fileName}
                                </NavLink>
                                <div className={Styles.dataContainer}>
                                    {showSignButton(state, roles?.[0]) ? (
                                        <>
                                            <Button
                                                class={`${Styles.signButton} ${state === 'FORBID_SIGN' ? Styles.signButtonForbid : ''}`}
                                                title={'Підписати'}
                                                tooltipText={state === 'FORBID_SIGN' ? 'Необхідно завантажити новий файл' : null}
                                                onClick={() => {
                                                    if (state === 'FORBID_SIGN') {
                                                        toggleIsErrorVisible();
                                                        return;
                                                    }
                                                    setFileName(fileName);
                                                    signFn(fileName);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                    {showRemoveButton(state, roles?.[0]) ? (
                                        <Button
                                            class={Styles.removeButton}
                                            title={'Видалити'}
                                            onClick={() => {
                                                removeFn(fileName);
                                            }}
                                        />
                                    ) : null}
                                    <div className={Styles.data}>{dateFormatFn(createAt)}</div>
                                    {currentFilter === 'deleted' ? <div className={Styles.data}>{dateFormatFn(modifiedAt)}</div> : null}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <Loader />
                )}
            </div>
            {shouldErrorRender ? (
                <div className={`${Styles.errorMessage} ${isErrorVisible ? `${Styles.fadeIn}` : `${Styles.fadeOut}`}`}>
                    {/*Система вимагає завантажити новий файл*/}
                    Необхідно завантажити новий файл
                </div>
            ) : null}

            <Pagination key={currentFilter + keyForPagination} totalPages={journalFilesData?.totalPages} action={paginationClick} />
        </>
    );
};

export default JournalFiles;
