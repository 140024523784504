//Core
import React, { useState } from 'react';
//Styles
import Styles from './styles.module.css';

const Button = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <>
            <button
                {...props}
                className={`${Styles.btn} ${props?.class}`}
                onClick={props?.onClick}
                type={props?.type ? props.type : 'button'}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {props?.title}
            </button>
            {showTooltip && props.tooltipText ? <div className={Styles.tooltip}>{props.tooltipText}</div> : null}
        </>
    );
};

export default Button;
