// Core
import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'authData',
    initialState: {
        data: null,
        params: null,
    },
    //For local development *****
    // initialState: {
    //     // data: { roles: ['USER'] },
    //     data: { roles: ['STATE_OWNER', 'EDITOR'] },
    //     params: null,
    // },
    // *****
    reducers: {
        setAuthData: (state, action) => {
            state.data = action.payload;
        },
        setVariable: (state, action) => {
            state.params = action.payload;
        },
    },
});

export const { setAuthData, setVariable } = authSlice.actions;

export default authSlice.reducer;
