//Core
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';

const Pagination = ({ totalPages, action }) => {
    const navigate = useNavigate();
    const [currentPaginationPage, setCurrentPaginationPage] = useState(0);
    const setPaginationStyle = (pageNumber) => {
        return currentPaginationPage === pageNumber ? Styles.active_page : Styles.page;
    };

    const getPages = () => {
        const siblingCount = 2;
        const totalPageNumbers = siblingCount * 2 + 5;

        if (totalPages <= totalPageNumbers) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }
        const leftSiblingIndex = Math.max(currentPaginationPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(currentPaginationPage + siblingCount, totalPages);

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPages - 2;
        const firsPage = 1;
        const lastPage = totalPages;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftRange = Array.from({ length: 3 + 2 * siblingCount }, (_, i) => i + 1);
            return [...leftRange, '...', totalPages];
        }
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightRange = Array.from({ length: 3 + 2 * siblingCount }, (_, i) => totalPages - (3 + 2 * siblingCount) + i + 1);
            return [firsPage, '...', ...rightRange];
        }
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = Array.from({ length: rightSiblingIndex - leftSiblingIndex + 1 }, (_, i) => leftSiblingIndex + i + 1);
            return [firsPage, '...', ...middleRange, '...', lastPage];
        }
        return [];
    };

    return totalPages > 1 ? (
        <div className={Styles.paginationContainer}>
            {getPages().map((item) => {
                return (
                    <div
                        className={setPaginationStyle(item - 1)}
                        // style={currentPaginationPage === i ? { color: '#2c6dff' } : null}
                        onClick={() => {
                            if (item === '...') {
                                return;
                            }
                            action(navigate, item - 1);
                            setCurrentPaginationPage(item - 1);
                            window.scrollTo(0, 0);
                        }}
                    >
                        <span>{item}</span>
                    </div>
                );
            })}
        </div>
    ) : null;
};

export default Pagination;
